import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip } from 'recharts';

export interface RPEProp {
  title: string;
  amount: number;
}

export interface RPEBarChartProps {
  data: RPEProp[];
  total: string;
  title: string;
  color: string;
  subtitle: string;
}

const RPEBarChart: React.FC<RPEBarChartProps> = ({ data, title, total, subtitle, color }: RPEBarChartProps) => {
  return (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
        <h3 className="tracking-tight text-base font-normal">{title}</h3>
      </div>
      <div className="p-6 pt-0">
        <div className="text-2xl font-bold">
          {total}
          {/* {(data.map((it) => it.amount).reduce((a, b) => a + b) / data.length).toFixed(2)}% */}
        </div>
        <p className="text-xs text-muted-foreground">{subtitle}</p>
        <div className="mt-4 h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart width={150} height={40} data={data}>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="rounded-lg border bg-background p-2 shadow-sm">
                        <div className="grid grid-cols-2 gap-2">
                          <div className="flex flex-col">
                            <span className="text-[0.70rem] uppercase text-muted-foreground">
                              {payload[0].payload.title}
                            </span>
                            <span className="text-sm">{payload[0].value}</span>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return null;
                }}
              />

              <Bar
                dataKey="amount"
                style={
                  {
                    fill: color,
                    opacity: 1,
                  } as React.CSSProperties
                }
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default RPEBarChart;
